<template>
    <div id="websiteDetail" :style="'overflow: auto; height: calc(100vh - 225px)'">
        <v-expand-transition>
            <router-view/>
        </v-expand-transition>
    </div>
</template>
<script>
import refreshData from "@/mixins/mixins";

export default {
    name:"WebsiteDetail",
    mixins: [refreshData],
    data() {
        return {
            apiUrl: "/website/" + this.$route.params.website_id + "/get",
            mutation: "website/setWebsiteData",
        }
    },
    created() {
        this.refreshData();
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        next();
    },
}
</script>