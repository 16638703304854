<template>
    <div style="height:100%;">
        <WorkFlowComponent v-if="workflow.config" :workflow="workflow" :stats="stats" :operationId="operationId" @saveWorkflows="saveWorkflows" :config="workflow.config" />    
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import WorkFlowComponent from '@/modules/workflow/views/WorkFlowComponent'
export default {
    name:"WorkflowDetail",
    components: {WorkFlowComponent},
    data() {
        return {
            workflow: {},
            operationId: null,
            operation_type_id: null,
            stats: {}
        }
    },
    created() {
        if(this.$route.params.operation_id){
            this.operationId = this.$route.params.operation_id
            GenericDataService.getData('/workflow/'+this.$route.params.workflow_id+'/getStats').then((response) => {
                this.stats = response.data.data
            })
        }
         if(this.$route.params.operationtype_id){
            this.operation_type_id = this.$route.params.operationtype_id
        }
        let workflow_id = this.$route.params.workflow_id
        GenericDataService.getData('/workflow/'+workflow_id+'/get').then((response) => {
            this.workflow = response.data.data
            if(this.workflow.trigger && this.workflow.trigger.contexts)
                this.workflow.trigger.contexts = JSON.parse(this.workflow.trigger.contexts);
            this.$store.dispatch('SET_WORKFLOW_NAME', this.workflow.name)
        })
    },
    methods: {
        saveWorkflows(){
            let url = ""
            let payload = {}
            if(this.operationId && this.workflow.ope_id != this.operationId){
                payload = this._.cloneDeep(this.workflow)
                payload.ope_id = this.operationId
                delete payload.id
            } else if(this.operation_type_id &&  this.workflow.type_ope_id != this.operation_type_id) {
                payload = this._.cloneDeep(this.workflow)
                payload.type_ope_id = this.operation_type_id
                delete payload.id
            } else {
                payload = this._.cloneDeep(this.workflow)
            }
            GenericDataService.postData('/workflow/set', payload).then((response) => {
                GenericDataService.getData('/workflow/'+this.$route.params.workflow_id+'/get').then((response) => {
                    this.workflow = response.data.data
                    if(this.workflow.trigger && this.workflow.trigger.contexts)
                        this.workflow.trigger.contexts = JSON.parse(this.workflow.trigger.contexts);
                    this.$store.dispatch('SET_WORKFLOW_NAME', this.workflow.name)
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                        this.$store.dispatch("REMOVE_SNACK")
                    });
                })
                
            })
        }
    },
}
</script>