<template>
    <div id="brandDetail" :style="'overflow: auto; height: calc(100vh - 225px)'">
        <v-expand-transition>
            <router-view/>
        </v-expand-transition>
    </div>
</template>
<script>
import refreshData from "@/mixins/mixins";

export default {
    name:"BrandDetail",
    mixins: [refreshData],
    data() {
        return {
            apiUrl: "/brand/"+this.$route.params.brand_id+"/get",
            mutation: "brand/setBrandData",
        }
    },
    created() {
        this.refreshData();
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        next();
    },
}
</script>
